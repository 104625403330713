
.main-image{
  height:90vh;
}

.sample-item-title{
  margin-top:5vh;
}

.back-button{
  background-color:rgba(54, 96, 127,0.8);
  border:1px solid black;
  padding:1vh 3vw;
  border-radius:5px;
  font-size:20px;
  color:white;
  left:10vw;
  top:15vh;
}

.back-button:focus{
  outline:none;
}
.back-button:hover{
  cursor:pointer;
  background-color:rgba(54, 96, 127,1);
}
.image-toggle{
  height:50vh;
  margin-top:5vh;
  padding-left:1vw;
  float:left;
  display:flex;
  flex-direction:column;
}
.image-box{
  margin-left:5vw;
  width:10vw;
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items:center;
}
.toggle-image{
  display:block;
  float:left;
  height:10vw;
}
.toggle-image:hover{
  cursor:pointer;
  height:12vw;
}
.filling-desc {
  color:rgba(54, 96, 127,0.8);
  padding:1vw;
}
.filling-subtitle {
  text-align: left;
  width:20vw;
}
