.slide{
  float:left;
  width:33.3%;
  padding:5px;
  height:40vh;
  object-fit:contain;
  opacity:0.5;
  transition:opacity 300ms linear, transform 300ms cubic-bezier(0.455,0.03,0.515,0.955);
}

.active-card{
  opacity:1;
  transform:scale(1.2);
}

.slide:hover{
  cursor:pointer;
}
