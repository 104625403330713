.sampling-section{
  height:80vh;
  width:100%;
  background-color:#B2ABAF;
  color:#51485A;
  text-align:center;
}

.sampling-header{
  margin-top:5vh;
  font-family:'News Cycle';
}
