.request-main{
  display:flex;
  justify-content: center;
  text-align: center;
  height:70vh;
  background:linear-gradient(white,rgba(50, 134, 166, 0.6));
}

.request-page-header{
  margin-top:20vh;
  color:rgba(54, 96, 127,1);
}

.request-page-content{
  display:flex;
  flex-direction:column;
}
