.address-wrapper{
  display:flex;
  flex-direction:row;
  justify-content: space-around;
  /* background-color:#B2ABAF; */
  background-color:black;
  /* color:#51485A; */
  color:white;
}

.address{
  padding:5vh;
  font-size: calc(2px + 2vmin);
  text-align: left;
}

.address i{
  padding-right:0.5vw;
}

.footer-lang{
  display:flex;
  justify-content: flex-end;
  padding-right:5vw;
}

.active-lang{
  background-color:white !important;
  color:rgba(50, 134, 166,1) !important;
}

.footer-lang p {
  padding:5vh 2vw;
}

.lang:hover {
  cursor:pointer;
  color:blue;
  color:rgba(50, 134, 166,1)
}
