.about-section-content{
  display:flex;
  justify-content: center;
  padding:0 5vw;
  padding-bottom:5vh;
  height:150%;
}

.gender-division{
  width:100%;
  background-color:grey;
  color:white;
  text-align: left;
  display:flex;
  align-items: center;
  padding: 2vh 2vw;
}

.catalog{
  width:85vw;
  height:110vh;
}


.catalog-img-wrap{
  width:15vw;
  padding:2vh 2vw;
  color:black;
  text-align:left;
  display:inline-block;
  vertical-align: top;
}

.catalog-img-wrap:hover{
   transform: scale(1.1);
}

.catalog-img{
  width:100%;
}


.catalog-item-desc{
  padding:1vh 1vw;
  height:10vh;
}

.catalog-img-title{
  font-size:15px;
}

.catalog-img-serial{
  font-size:13px;
}
