.card-slider{
  height:50vh;
  width:50vw;
  position:relative;
  margin: 0 auto;
  overflow:hidden;
  top:-18%;
}

.card-slider.active-slide-1 #card-1{
  opacity: 1;
}

.card-slider-wrapper{
  display:flex;
  position:absolute;
  width:60vw;
  height:50vh;
  transition: transform 300ms cubic-bezier(0.455,0.03,0.515,0.955);
}

.carousel{
  display:flex;
  height:40vh;
}

.carousel button{
  background-color:rgba(30,30,30,0.3);
  border:none;
  color:rgba(255,255,255,0.5);
  cursor:pointer;
}

.carousel button:focus{
  outline:0;
}

.carousel button:hover{
  background-color:rgba(30,30,30,0.5);
  color:rgba(255,255,255,1);
  transition-duration:0.2s;
}

.carousel i{
  font-size:3vw;
}
