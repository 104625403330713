.request-section{
  background-color:#36607f;
  color:white;
  height:50vh;
  display:flex;
  justify-content:center;
  flex-direction:column;
  text-align:center;
  font-size: calc(5px + 2vmin);
  /* border-top:65px solid #f5f6fa;
  border-left:50px solid #36607f;
  border-right:50px solid #36607f; */
  padding:5vh 0;
}

.request-section a{
  color:inherit;
  text-decoration: none;
}

.request-button-wrapper{
  display:flex;
  justify-content: center;
}

.request-header{
  color:rgba(0,0,0,0.6);
  border:1px solid black;
  /* background-color:rgba(255,165,0,1); */
  background-image: linear-gradient(#b5cee3,#90b5d5);
  border-radius:50px;
  padding:5vh 5vw;
  box-shadow:0px 1.5vh rgb(29, 44, 58);
  transition-duration:0.2s;
  font-family:'News Cycle';
}

.request-header:hover{
  transform:translateY(1.5vh);
  box-shadow:0px 0px black;
}
