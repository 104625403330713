.samples-page{
  height:auto;
  background-color:white;
  text-align:center;
  color:black;
  margin-top:10vh;
  height:270vh;
}

.samples-title{
  padding:5vh;
  color:grey;
}

.gender-toggle{
  display:flex;
  list-style:none;
  position:absolute;
  right:10vw;
  top:14vh;
}

.page-toggle{
  display:flex;
  list-style:none;
  position:absolute;
  right:10vw;
  top:18vh;
  margin: 0 1vw;
}

.page-toggle h2{
  padding:0 0.2vw;
}

.page-toggle h3{
  padding: 0 0.5vw;
}

.gender-toggle h2{
  padding:0 0.1vw;
}

.active{
  color:black;
  font-weight:bold;
}

.gender-button:hover{
color:black;
cursor:pointer;
}

a{
  text-decoration:none;
}
a:visited{
  color:inherit;
}
