.App-header {
  background-color: #282c34;
  min-height: 33.3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image:url("coat.jpg");
  background-repeat:no-repeat;
  background-size: cover;
}

.App-header2{
  position:fixed;
  width:100%;
  top:0;
  z-index: 20;
  display:flex;
  justify-content: space-between;
  border-bottom:1px solid black;
  background-color:#36607f;
  color:white;
}

.logo-wrapper{
  margin-left:10vw;
}

.is-active2{
  text-decoration:none;
  background-color:white;
  color:#36607f !important;
}

.nav-wrapper2{
  display:flex;
  flex-direction:column;
  justify-content: center;
  margin-right:10vw;
}

.nav2{
  display:flex;
  justify-content: center;
  text-decoration:none;
  list-style:none;
}

.nav2 a{
  text-decoration:none;
  color:white;
  padding:1vh 1vw;
}

.nav2 a:visited{
  text-decoration:none;
  color:white;
}

.nav2 a:hover{
  background-color:white;
  color:#36607f !important;
  transition-duration:0.5s;
}

#title{
  font-family:'Cute Font';
  font-size:80px;
  color:white;
  padding:20px;
  background-color:rgba(54, 96, 127,0.6);
}
