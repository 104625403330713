.request-form-header{
  margin:2.5vh 0;
  height:20%;
  width:100%;
  color:white;
}

.request-form-content{
  border-radius:30px;
  background-color:rgba(54, 96, 127,1);
  width:50vw;
}

.request-form-wrapper{
  height:80%;
  text-align:center;
  background-color:white;
  border-bottom-left-radius:30px;
  border-bottom-right-radius:30px;
}

.request-form{
  padding:2vh 0;
  text-align: left;
}

.request-form input:focus{
  outline:none;
}

.request-form textarea:focus{
  outline:none;
}

.request-form button:focus{
  outline:none;
}

.request-form label{
  font-weight:bold;
  color:rgba(54, 96, 127,1);
}

.request-form input{
  height:5vh;
  width:95%;
  margin:1vh 0;
  padding:0 1vw;
  font-size:calc(10px + 1vmin);
  border-radius:15px;
  border:1px solid rgba(54, 96, 127,1);

}
.request-form textarea{
  border-radius:16px;
  margin:1vh 0;
  padding:1vh 1vw;
  font-size:calc(10px + 1vmin);
}

.row{
  display:flex;
  justify-content: space-around;
}

.column{
  margin:0 2vw;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
}

.message{
  width:89%;
}

.submit{
  border-radius:30px;
  width:93%;
  margin:1vh 2vw;
  padding:2vh 1vw;
  background-color:rgba(54, 96, 127,0.8);
  color:white;
  font-size:calc(10px + 1vmin);
}

.submit:hover{
  background-color:rgba(54, 96, 127,1);
  color:white;
  cursor:pointer;
}
