
.App-header2{
  position:fixed;
  width:100%;
  top:0;
  z-index: 20;
  display:flex;
  justify-content: space-between;
  border-bottom:1px solid black;
  background-color:#36607f;
  color:white;
  height:10vh;
}

.logo-wrapper{
  display:flex;
  justify-content: center;
  flex-direction:column;
  margin-left:10vw;
  font-family:'Lora',serif;
  font-size:26px;
  font-weight:bold;
}

.is-active2{
  text-decoration:none;
  background-color:white;
  color:#36607f !important;
}

.nav-wrapper2{
  display:flex;
  flex-direction:column;
  justify-content: center;
  margin-right:10vw;
}

.nav2{
  display:flex;
  justify-content: center;
  text-decoration:none;
  list-style:none;
}

.nav2 li{
  font-family:'Lora'
}

.nav2 a{
  text-decoration:none;
  color:white;
  padding:1vh 1vw;
}

.nav2 a:visited{
  text-decoration:none;
  color:white;
}

.nav2 a:hover{
  background-color:white;
  color:#36607f !important;
  transition-duration:0.5s;
}

#title{
  font-family:'Cute Font';
  font-size:80px;
  color:white;
  padding:20px;
  background-color:rgba(54, 96, 127,0.6);
}
