.homepage-product{
  margin-top:13vh;
  margin-bottom:10vh;
  color:rgba(54, 96, 127,1);
  font-family:'News Cycle';
}

.homepage-product-content{
  display:flex;
  justify-content: center;
  width:100%;
  padding-left:3vw;
  font-size: calc(8px + 1vw);
}

.homepage-product li{
  border:1px solid black;
  color:black;
  text-decoration:none;
}

.homepage-product a:hover{
  background-color: darkgrey;
  transition-duration:0.5s;
  cursor:pointer;
  color:black;
}

.slideshow{
  margin:0 20vw;
  display:flex;
  justify-content: space-between;
}

.slideshow-img{
  background-image:url('https://i.ibb.co/GQskwbm/trench.jpg');
  background-repeat:no-repeat;
  background-size:cover;
  min-width:200px;
  width:50vw;
  height:60vh;
  margin-top:2vh;
}

.slideshow img{
  width:100%;
  height:auto;
}

.slideshow li{
  list-style:none;
  border:none;
  font-weight:bold;
}

.slide-show-description{
  font-size: calc(3px + 1vw);
  text-align:center;
  padding:3vh;
  margin-top:5vh;
  margin-bottom:5vh;
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-left:3vw;
  background-color:rgba(54, 96, 127,0.8);
  color:white;
  border-radius:5px;
  transform: translateX(-10vw);
  min-width:100px;
  width:100%;
  height:50vh;
}

.slide-show-description li{
  color:white;
  padding:5px;
}

#coat{
  height:40%;
  width:40%;
}

.date{
  transform:translate(-15vw,7vh);
}
